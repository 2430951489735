/* eslint-disable react/no-danger */
import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './Footer.module.scss';
import IconInstagram from '../Icons/IconInstagram';
import IconYoutube from '../Icons/IconYoutube';
import IconFacebook from '../Icons/IconFacebook';
import IconTwitter from '../Icons/IconTwitter';

const year = () => {
  const date = new Date();
  return date.getFullYear();
};

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles['footer-wrap']}>
      <div className={styles['footer-item']}>
        <ul className={styles['footer-menu']}>
          <li>
            <NavLink to="/schedule" className={styles['footer-menu-link']}>
              Расписание
            </NavLink>
          </li>
          <li>
            <NavLink to="/shows" className={styles['footer-menu-link']}>
              шоу
            </NavLink>
          </li>
          <li>
            <NavLink to="/" className={styles['footer-menu-link']}>
              политика конфиденциальности
            </NavLink>
          </li>
          <li>
            <NavLink to="/this-day-in-history" className={styles['footer-menu-link']}>
              Этот день в истории
            </NavLink>
          </li>
          <li>
            <NavLink to="/connect" className={styles['footer-menu-link']}>
              Как подключиться
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={styles['footer-item']}>
        <NavLink className={styles['footer-link']} to="/">
          <img
            src="/assets/i/footer-logo.png"
            alt=""
            className={classNames(styles['footer-logo'])}
          />
        </NavLink>
      </div>
      <div className={styles['footer-item']}>
        <h3 className={styles['footer-subtitle']}>наши соцсети:</h3>
        <ul className={styles['footer-socials']}>
          {/* <li>
            <a
              href="http://instagram.com/history"
              className={styles['footer-social']}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Перейти в instagram"
            >
              <span className={classNames('visually-hidden')}>Перейти в instagram</span>
              <IconInstagram />
            </a>
          </li> */}
          {/*  <li>
            <a
              href="https://www.facebook.com/History/"
              className={styles['footer-social']}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Перейти на facebook"
            >
              <span className={classNames('visually-hidden')}>Перейти на facebook</span>
              <IconFacebook />
            </a>
          </li> */}
          <li>
            <a
              href="https://www.youtube.com/subscription_center?add_user=historychannel"
              className={styles['footer-social']}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Перейти на youtube"
            >
              <span className={classNames('visually-hidden')}>Перейти на youtube</span>
              <IconYoutube />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/history"
              className={styles['footer-social']}
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Перейти в twitter"
            >
              <span className={classNames('visually-hidden')}>Перейти в twitter</span>
              <IconTwitter />
            </a>
          </li>
        </ul>
        <p className={styles['footer-copyright']}>© {year()} A+E Networks. All Rights Reserved</p>
      </div>
    </div>
  </footer>
);
export default Footer;
