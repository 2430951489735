import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import * as HistoryDayApiService from '../../services/api/HistoryDayApiService';
import PageTitle from '../../components/Atoms/PageTitle';
import Gallery from '../../components/Gallery';
import styles from './HistoryDayPage.module.scss';
import Sharing from '../../components/Sharing';

interface MatchParams {
  slug: string;
}

interface State {
  loading: boolean;
  date: Moment;
  historyDays: HistoryDayApiService.HistoryDayShort[];
  historyDay: HistoryDayApiService.HistoryDayFull | null;
  historyDaysCache: Record<string, HistoryDayApiService.HistoryDayShort[]> | null;
}

class HistoryDayPage extends React.Component<RouteComponentProps<MatchParams>, State> {
  state: State = {
    loading: true,
    date: moment(),
    historyDays: [],
    historyDaysCache: null,
    historyDay: null
  };

  async componentDidMount() {
    this.setState({ loading: true });
    await this.fetchData();
    this.setState({ loading: false });
  }

  async componentDidUpdate(
    prevProps: Readonly<RouteComponentProps<MatchParams>>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const { match } = this.props;
    if (match.params.slug !== prevProps.match.params.slug) {
      window.scrollTo(0, 0);
      if (!match.params.slug) {
        this.reset();
      } else {
        await this.fetchData();
      }
    }
  }

  get text() {
    const { historyDay } = this.state;
    if (!historyDay) {
      return '';
    }

    let result = historyDay.text;
    const re = new RegExp('{{(\\s*gallery\\s*code=(\\w+)\\s*)}}', 'g');
    let matches;
    // eslint-disable-next-line no-cond-assign
    while ((matches = re.exec(result))) {
      const string = matches[0];
      const code = matches[2];
      result = result.replace(string, `<div data-gallery-code="${code}"></div>`);
    }
    return result;
  }

  static insertGalleries() {
    document.querySelectorAll('[data-gallery-code]').forEach(el => {
      const code = (el as HTMLElement).dataset.galleryCode;
      if (code) {
        ReactDOM.render(
          <Gallery code={code} />,
          document.querySelector(`[data-gallery-code="${code}"]`)
        );
      }
    });
  }

  reset() {
    // if user clicked on top menu
    this.setState({ date: moment() }, async () => {
      await this.fetchData();
    });
  }

  async fetchHistoryDays() {
    const { date, historyDaysCache } = this.state;
    const dateFormatted = date.format('MM.DD');
    if (historyDaysCache && historyDaysCache[dateFormatted]) {
      return historyDaysCache[dateFormatted];
    }
    const historyDays = await HistoryDayApiService.fetchDays(date.format('MM'), date.format('DD'));
    const newHistoryDaysCache = historyDaysCache ? { ...historyDaysCache } : {};
    newHistoryDaysCache[dateFormatted] = historyDays;
    this.setState({ historyDays, historyDaysCache: newHistoryDaysCache });
    return historyDays;
  }

  async fetchData() {
    await this.fetchHistoryDays();

    const {
      match: {
        params: { slug }
      }
    } = this.props;

    const { historyDays } = this.state;

    if (slug) {
      // if slug passed - we should prefetch article to get the date of the article to fetch other articles
      const historyDay = await HistoryDayApiService.fetchDay(slug);
      this.setState({ historyDay, date: moment(historyDay.date) }, HistoryDayPage.insertGalleries);
    }

    if (!slug && historyDays.length) {
      // if slug was not passed - just show first article from other articles
      const historyDay = await HistoryDayApiService.fetchDay(historyDays[0].slug);
      this.setState({ historyDay }, HistoryDayPage.insertGalleries);
    }
  }

  async detectSlugAndRedirect() {
    const { history } = this.props;

    // fetching other articles to get a slug of the first article
    const historyDays = await this.fetchHistoryDays();
    let path = '/this-day-in-history';
    if (historyDays.length) {
      path += `/${historyDays[0].slug}`;
    }
    history.push(path);
  }

  async onPickDate(date: Moment) {
    this.setState({ date }, this.detectSlugAndRedirect);
  }

  renderList() {
    const { historyDays } = this.state;
    if (!historyDays) {
      return null;
    }
    return (
      <section>
        <h2 className={styles['history-list-title']}>
          <span>Также в этот</span>
          <b> день</b>
        </h2>
        <ul className={styles['history-list']}>
          {historyDays.slice(1).map(one => (
            <li className={styles['history-list-item']} key={one.id}>
              <NavLink className={styles['item-link']} to={`/this-day-in-history/${one.slug}`}>
                <span className={styles['item-year']}>{moment(one.date).format('YYYY')}</span>
                <h3 className={styles['item-title']}>{one.title}</h3>
                {/* <p className={styles['item-text']}>{one.title}</p> */}
              </NavLink>
            </li>
          ))}
        </ul>
      </section>
    );
  }

  renderDetails() {
    const { historyDay } = this.state;
    if (!historyDay) {
      return null;
    }
    return (
      <article className={styles['history-detail']}>
        <header>
          {historyDay.image !==
            'http://in.history.test.notagency.ru/storage/images/e71ce9684cf11f8fd733c1cf4d7b0e6a.png' && (
            <figure className={styles['history-detail-fig']}>
              <img src={historyDay.image} alt="" />
              <figcaption>{historyDay.image_copyright}</figcaption>
            </figure>
          )}
          <div className={styles['additional-attributes']}>
            <dl className={styles['additional-attributes-year']}>
              <dd>
                {parseInt(moment(historyDay.date).format('Y'), 10)}{' '}
                {historyDay.date_ad ? 'до н.э.' : ''}
              </dd>
            </dl>
            <dl className={styles['additional-attributes-item']}>
              <dd>{moment(historyDay.date).format('D MMMM')}</dd>
            </dl>
          </div>
          <h1 className={styles['history-title']}>{historyDay.title}</h1>
        </header>
        <Sharing content="this-day-in-history" slug={historyDay.slug} />
        <div className={styles['history-body']} dangerouslySetInnerHTML={{ __html: this.text }} />
        {this.renderList()}
        <footer className={styles['history-footer']}>
          <h2 className={styles['citation-title']}>Информация для цитирования</h2>
          <div className={styles['citation-meta']}>
            <h3 className={styles['citation-subtitle']}>Название статьи</h3>
            <p className={styles['citation-text']}>{historyDay.title}</p>
          </div>
          <div className={styles['citation-meta']}>
            <h3 className={styles['citation-subtitle']}>Название вебсайта</h3>
            <p className={styles['citation-text']}>HISTORY</p>
          </div>
          <div className={styles['citation-meta']}>
            <h3 className={styles['citation-subtitle']}>URL</h3>
            <p className={styles['citation-text']}>
              <a href={window.location.href}>{window.location.href}</a>
            </p>
          </div>
          {historyDay.publisher ? (
            <div className={styles['citation-meta']}>
              <h3 className={styles['citation-subtitle']}>Издатель</h3>
              <p className={styles['citation-text']}>{historyDay.publisher}</p>
            </div>
          ) : null}
          <div className={styles['citation-meta']}>
            <h3 className={styles['citation-subtitle']}>Обновлено</h3>
            <p className={styles['citation-text']}>
              {moment(historyDay.updated_at).format('DD.MM.YYYY')}
            </p>
          </div>
          <div className={styles['citation-meta']}>
            <h3 className={styles['citation-subtitle']}>Опубликовано</h3>
            <p className={styles['citation-text']}>
              {moment(historyDay.original_published_date).format('DD.MM.YYYY')}
            </p>
          </div>
        </footer>
      </article>
    );
  }

  render() {
    const { date, loading } = this.state;
    if (loading) {
      return null;
    }
    return (
      <section className={classNames(styles['history-page'])}>
        <PageTitle
          title="Этот день в <b>истории</b>"
          withCalendar
          calendarValue={date}
          onPickDate={newDate => this.onPickDate(newDate)}
        />
        <div className={classNames(styles.container)}>
          {this.renderDetails()}
        </div>
      </section>
    );
  }
}

export default HistoryDayPage;
