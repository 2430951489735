import React from 'react';
import classNames from 'classnames/bind';
import * as GalleryApiService from '../../services/api/GalleryApiService';
import GalleryModal from '../GalleryModal';

import styles from './Gallery.module.scss';

interface Props {
  code: string;
}

const Gallery = ({ code }: Props) => {
  const [images, setImages] = React.useState<GalleryApiService.Image[]>([]);
  const [showModal, setShowModal] = React.useState(false);

  const mounted = React.useRef<boolean>();
  React.useEffect(() => {
    if (!mounted.current) {
      const onMount = async () => {
        setImages(await GalleryApiService.fetchImages(code));
      };
      onMount();
      mounted.current = true;
    }
  });

  if (!images[0]) {
    return null;
  }

  return (
    <div className={classNames([styles.container])}>
      <img src={images[0].image} alt="" width="100%" />
      <button type="button" className={styles['images-count']} onClick={() => setShowModal(true)}>
        <div className={styles['images-count-text']}>{images.length}</div>
      </button>
      {showModal && (
        <GalleryModal
          images={images}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Gallery;
