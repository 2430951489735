import React from 'react';
import moment, { Moment } from 'moment';
import SwiperCore, { History, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames/bind';

import { NavLink } from 'react-router-dom';
import styles from './ScheduleList.module.scss';

interface Props {
  activeDate: Moment;
}

interface State {
  dates: Moment[];
  slideCount: number;
}

class ScheduleCalendar extends React.Component<Props, State> {
  state: State = {
    dates: [],
    slideCount: (window.innerWidth >= 767 && 7) || 3
  };

  refPrev = React.createRef<HTMLButtonElement>();

  refNext = React.createRef<HTMLButtonElement>();

  swiperInstance: SwiperCore | null = null;

  async componentDidMount() {
    SwiperCore.use([Navigation, History]);
    this.initDates();
    this.slideToActiveDate();
    window.addEventListener('resize', this.handleResize);
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    this.slideToActiveDate();
  }

  get activeDateIndex() {
    const { activeDate } = this.props;
    const { dates } = this.state;
    return (
      (activeDate &&
        dates.findIndex(one => activeDate.format('YYYY-MM-DD') === one.format('YYYY-MM-DD'))) ||
      0
    );
  }

  handleResize = () => {
    const windowSize = window.innerWidth;
    const slideCount = (windowSize >= 767 && 7) || 3;
    this.setState({ slideCount });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  initDates() {
    const from = moment()
      .subtract(1, 'week')
      .unix();

    const to = moment()
      .add(2, 'weeks')
      .unix();
    const dates = [];
    for (let timestamp = from; timestamp <= to; timestamp += 86400) {
      dates.push(moment.unix(timestamp));
    }
    this.setState({ dates });
  }

  slideToActiveDate() {
    if (this.swiperInstance) {
      this.swiperInstance.slideTo(this.activeDateIndex, 0);
    }
  }

  render() {
    const { dates, slideCount } = this.state;
    return (
      <div className={classNames(styles.container)}>
        <Swiper
          slidesPerView={slideCount}
          slidesPerGroup={slideCount}
          spaceBetween={24}
          onSwiper={instance => {
            this.swiperInstance = instance;
          }}
          onSlideChange={instance => {
            if (this.refPrev.current) {
              this.refPrev.current.disabled = instance.activeIndex === 0;
            }
            if (this.refNext.current && instance.params.slidesPerGroup) {
              this.refNext.current.disabled =
                instance.activeIndex + instance.params.slidesPerGroup === instance.slides.length;
            }
          }}
        >
          {dates.map((date, index) => (
            <SwiperSlide key={date.unix()}>
              <NavLink
                className={classNames([
                  { [styles.active]: this.activeDateIndex === index },
                  styles.date
                ])}
                to={`/schedule/${date.format('YYYY')}-${date.format('MM')}-${date.format('DD')}`}
              >
                <div className={styles['date-day-month']}>
                  {date.format('DD')} {date.format('MMM')}
                </div>
                <div className={styles['date-week']}>{date.format('ddd')}</div>
              </NavLink>
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          type="button"
          className={classNames(styles['nav-button'], styles.prev)}
          ref={this.refPrev}
          onClick={() => {
            if (this.swiperInstance) {
              this.swiperInstance.slidePrev();
            }
          }}
        />
        <button
          type="button"
          className={classNames(styles['nav-button'], styles.next)}
          ref={this.refNext}
          onClick={() => {
            if (this.swiperInstance) {
              this.swiperInstance.slideNext();
            }
          }}
        />
      </div>
    );
  }
}

export default ScheduleCalendar;
