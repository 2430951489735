import HistoryApiService from './HistoryApiService';

export interface Programme {
  id: string;
  new: boolean;
  time: string;
  show_title: string; // eslint-disable-line camelcase
  description: string;
  episode_title: string; // eslint-disable-line camelcase
  season_number: string; // eslint-disable-line camelcase
  episode_number: string; // eslint-disable-line camelcase
  rating: string;
  image: string;
}

export const fetchTimetable = async (
  day: string,
  month: string,
  year: string
): Promise<Programme[]> =>
  new HistoryApiService().setEndpoint(`/timetable/${year}/${month}/${day}`).get();
