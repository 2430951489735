import React from 'react';
import classNames from 'classnames';
import styles from './Sharing.module.scss';
import IconFillFacebook from '../Icons/IconFillFacebook';
import IconFillTwitter from '../Icons/IconFillTwitter';
import IconFillPinterest from '../Icons/IconFillPinterest';
// import IconFillPrint from '../Icons/IconFillPrint';

interface Props {
  content: string;
  slug: string;
}

const Sharing = ({ content, slug }: Props) => {
  const shareUrl = encodeURIComponent(
    `${window.location.protocol}//${window.location.hostname}/share?content=${content}&slug=${slug}`
  );
  const openShare = (url: string, target: string) => {
    window.open(
      url,
      target,
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=500'
    );
  };
  const shareOnFacebook = () => {
    openShare(`https://www.facebook.com/sharer.php?u=${shareUrl}`, 'share_fb');
  };
  const shareOnTwitter = () => {
    openShare(`https://twitter.com/intent/tweet?url=${shareUrl}`, 'share_tw');
  };
  const shareOnPinterest = () => {
    openShare(`http://pinterest.com/pin/create/link/?url=${shareUrl}`, 'share_pinterest');
  };
  return (
    <section className={styles['history-socials']}>
      <ul className={styles['history-socials-list']}>
        {/* <li>
          <button
            type="button"
            className={classNames(styles['history-social-link'], styles.facebook)}
            onClick={shareOnFacebook}
          >
            <IconFillFacebook />
          </button>
        </li> */}
        <li>
          <button
            type="button"
            className={classNames(styles['history-social-link'], styles.twitter)}
            onClick={shareOnTwitter}
          >
            <IconFillTwitter />
          </button>
        </li>
        <li>
          <button
            type="button"
            className={classNames(styles['history-social-link'], styles.pinterest)}
            onClick={shareOnPinterest}
          >
            <IconFillPinterest />
          </button>
        </li>
        {/* <li>
          <button className={classNames(styles['history-social-link'], styles.print)} type="button">
            <IconFillPrint />
          </button>
        </li> */}
      </ul>
    </section>
  );
};

export default React.memo(Sharing);
