import React from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';

import styles from './Calendar.module.scss';

interface Props {
  value: Moment;
  onSelect(date: Moment): void;
}

const year = 2020;

const Calendar = ({ value, onSelect }: Props) => {
  const [month, setMonth] = React.useState<number>(value.month());

  const decreaseMonth = () => {
    if (month === 0) {
      setMonth(11);
    } else {
      setMonth(month - 1);
    }
  };

  const increaseMonth = () => {
    if (month === 11) {
      setMonth(0);
    } else {
      setMonth(month + 1);
    }
  };

  const days = () => {
    const monthIndex = month; // 0..11 instead of 1..12
    const date = new Date(year, monthIndex, 1);
    const result = [];
    while (date.getMonth() === monthIndex) {
      result.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }
    return result;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button
          type="button"
          className={classNames(styles['nav-button'], styles.prev)}
          onClick={decreaseMonth}
        />
        <div className={styles.month}>
          {moment()
            .set('month', month)
            .format('MMMM')}
        </div>
        <button
          type="button"
          className={classNames(styles['nav-button'], styles.next)}
          onClick={increaseMonth}
        />
      </div>
      <div className={styles.days}>
        {days().map(day => (
          <button
            type="button"
            className={classNames([
              styles.day,
              { [styles.current]: day === value.date() && month === value.month() }
            ])}
            key={day}
            onClick={() =>
              onSelect(
                moment()
                  .set('date', day)
                  .set('month', month)
                  .set('year', year)
              )
            }
          >
            {day}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
