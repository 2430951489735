import HistoryApiService from './HistoryApiService';

export interface HistoryDayShort {
  id: string;
  title: string;
  slug: string;
  text: string;
  date: string;
  date_ad: string;
}

export interface HistoryDayFull {
  id: string;
  title: string;
  slug: string;
  date: string;
  date_ad: string;
  original_published_date: string; // eslint-disable-line camelcase
  image: string;
  image_copyright: string; // eslint-disable-line camelcase
  text: string;
  publisher: string;
  created_at: string; // eslint-disable-line camelcase
  updated_at: string; // eslint-disable-line camelcase
}

export const fetchDays = async (month: string, day: string): Promise<HistoryDayShort[]> =>
  new HistoryApiService().setEndpoint(`/history-days/${month}/${day}`).get();

export const fetchDay = async (slug: string): Promise<HistoryDayFull> =>
  new HistoryApiService().setEndpoint(`/history-days/details/${slug}`).get();
