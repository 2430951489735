import urls from '../../constants/Urls';
import ApiAbstract from '../../lib/Api/ApiAbstract';

export default class AngeliniApiService extends ApiAbstract {
  protected version = 'v1';

  protected baseUrl = urls.api.baseUrl;

  constructor(version?: string) {
    super();
    this.version = version || this.version;
  }

  public getUri() {
    if (this.endpoint) {
      return `${this.baseUrl}/${this.version}${this.endpoint}`;
    }
    return undefined;
  }

  public handleResponse(data: any) {
    return data.data;
  }
}
