import React from 'react';

const IconFillTwitter = () => (
  <svg
    aria-hidden="false"
    role="img"
    viewBox="0 0 24.62 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M24.62 2.38A10.85 10.85 0 0122.09 5v.66A14.26 14.26 0 017.75 20 14.26 14.26 0 010 17.73a10.41 10.41 0 001.22.06 10.11 10.11 0 006.27-2.16 5.06 5.06 0 01-4.72-3.5 6.35 6.35 0 001 .08A5.35 5.35 0 005 12a5 5 0 01-4-5 5.09 5.09 0 002.28.64A5.05 5.05 0 011.72.92 14.34 14.34 0 0012.13 6.2 5.71 5.71 0 0112 5a5 5 0 015-5 5 5 0 013.69 1.59A9.94 9.94 0 0023.94.38a5 5 0 01-2.22 2.78 10.12 10.12 0 002.91-.78z"
    />
  </svg>
);

export default IconFillTwitter;
