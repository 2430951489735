import React from 'react';

const IconYoutube = () => (
  <svg
    aria-hidden="false"
    role="img"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9994 9.84619C6.8484 9.84619 6.63574 10.8677 6.63574 18.8462C6.63574 26.8246 6.8484 27.8462 18.9994 27.8462C31.1504 27.8462 31.363 26.8246 31.363 18.8462C31.363 10.8677 31.1504 9.84619 18.9994 9.84619ZM22.9619 19.2366L17.4107 21.6865C16.9248 21.8992 16.5267 21.6607 16.5267 21.1535V16.5389C16.5267 16.0328 16.9248 15.7932 17.4107 16.0059L22.9619 18.4558C23.4478 18.6709 23.4478 19.0215 22.9619 19.2366Z"
      stroke="white"
      strokeWidth="1.74922"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconYoutube;
