import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import styles from './SchedulePage.module.scss';
import PageTitle from '../../components/Atoms/PageTitle';
import ScheduleList from '../../components/Schedule/ScheduleList';
import ScheduleCalendar from '../../components/Schedule/ScheduleCalendar';

interface MatchParams {
  date: string;
}

const SchedulePage = ({ match }: RouteComponentProps<MatchParams>) => {
  const dateFromPath = moment(match.params.date, 'YYYY-MM-DD');
  const activeDate = dateFromPath.isValid() ? dateFromPath : moment();

  return (
    <div className={classNames(styles.container)}>
      <PageTitle title="Расписание" />
      <div className={styles.inner}>
        <ScheduleCalendar activeDate={activeDate} />
        <ScheduleList date={activeDate} />
      </div>
    </div>
  );
};

export default SchedulePage;
