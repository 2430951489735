import React from 'react';
import classNames from 'classnames';
import styles from './BurgerButton.module.scss';

interface Props {
  onClick: React.MouseEventHandler<HTMLElement>;
  active: boolean;
}

interface State {
  active: boolean;
}

export default class BurgerButton extends React.Component<Props, State> {
  static defaultProps = {
    active: false,
    onClick: () => {}
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      active: props.active
    };
  }

  shouldComponentUpdate(nextProps: Props) {
    const { active } = this.props;
    if (active !== nextProps.active) {
      this.setState({ active: nextProps.active });
      return false;
    }
    return true;
  }

  onClick = (e: any) => {
    this.toggleClass();
    const { onClick } = this.props;
    onClick(e);
  };

  toggleClass() {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  }

  render() {
    const { active } = this.state;
    return (
      <button
        type="button"
        onClick={this.onClick}
        className={classNames(styles.button, active ? styles.cross : '')}
      >
        <span className={styles.line} />
        <span className={styles.line} />
        <span className={styles.line} />
      </button>
    );
  }
}
