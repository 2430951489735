import HistoryApiService from './HistoryApiService';

export interface Shows {
  popular: Show[];
  regular: Show[];
}

export interface Show {
  id: string;
  title: string;
  slug: string;
  description: string;
  preview_image: string; // eslint-disable-line camelcase
  seasons: string;
  episodes: string;
}

export interface ShowFull extends Show {
  detail_image: string; // eslint-disable-line camelcase
  text: string;
}

export const fetchShows = async (): Promise<Shows> =>
  new HistoryApiService().setEndpoint('/shows').get();

export const fetchShow = async (slug: string): Promise<ShowFull> =>
  new HistoryApiService().setEndpoint(`/shows/${slug}`).get();
