import React from 'react';
import { Router, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import history from './history';
import CommonLayout from './layouts/CommonLayout';
import MainPage from './pages/MainPage';
import ShowsPage from './pages/ShowsPage';
import ShowPage from './pages/ShowPage';
import SchedulePage from './pages/SchedulePage';
import HistoryDayPage from './pages/HistoryDayPage';
import ConnectPage from './pages/ConnectPage';

export default () => (
  <Router history={history}>
    <Switch>
      <PublicRoute exact path="/" component={CommonLayout} page={MainPage} />
      <PublicRoute exact path="/shows" component={CommonLayout} page={ShowsPage} />
      <PublicRoute path="/shows/:slug" component={CommonLayout} page={ShowPage} />
      <PublicRoute exact path="/schedule" component={CommonLayout} page={SchedulePage} />
      <PublicRoute path="/schedule/:date" component={CommonLayout} page={SchedulePage} />
      <PublicRoute
        exact
        path="/this-day-in-history"
        component={CommonLayout}
        page={HistoryDayPage}
      />
      <PublicRoute
        path="/this-day-in-history/:slug"
        component={CommonLayout}
        page={HistoryDayPage}
      />
      <PublicRoute path="/connect" component={CommonLayout} page={ConnectPage} />
    </Switch>
  </Router>
);
