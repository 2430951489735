import React from 'react';
import classNames from 'classnames';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import * as ShowsApiService from '../../services/api/ShowsApiService';
import styles from './ShowsPage.module.scss';
import PageTitle from '../../components/Atoms/PageTitle';

interface MatchParams {}

interface Props extends RouteComponentProps<MatchParams> {}

const ShowsPage = (props: Props) => {
  const [shows, setShows] = React.useState<ShowsApiService.Shows>({ popular: [], regular: [] });
  const [showsFiltered, setShowsFiltered] = React.useState<ShowsApiService.Show[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const mounted = React.useRef<boolean>();
  React.useEffect(() => {
    if (!mounted.current) {
      const onMount = async () => {
        const showsResponse = await ShowsApiService.fetchShows();
        setShows(showsResponse);
        setShowsFiltered(showsResponse.regular);
      };
      onMount();
      mounted.current = true;
    }
  });

  return (
    <section className={styles['shows-page']}>
      <PageTitle title="Программы" />
      <div className={classNames(styles.content)}>
        <h2 className={styles['content-title']}>Популярные</h2>
        <ul className={styles['content-list']}>
          {shows.popular.map(show => (
            <li key={show.id}>
              <NavLink className={styles['content-item']} to={`/shows/${show.slug}`}>
                <img src={show.preview_image} alt="" />
                <div className={styles['content-item-detail']}>
                  <h3>{show.title}</h3>
                  <small>{show.description}</small>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['content-shaded']}>
        <div className={classNames(styles.content, styles['content-inner'])}>
          <header className={styles['content-shaded-head']}>
            <h2 className={styles['content-title']}>Все шоу</h2>
            <div className={styles['search-box']}>
              <input
                type="text"
                name="show-keyword"
                value={searchQuery}
                placeholder="Поиск шоу"
                className={styles['show-keyword']}
                onChange={event => {
                  setShowsFiltered(
                    shows.regular.filter(show => {
                      return (
                        (event.target as any).value === '' ||
                        show.title.indexOf((event.target as any).value) !== -1
                      );
                    })
                  );
                  setSearchQuery((event.target as any).value);
                }}
              />
              {searchQuery.length > 0 ? (
                <button
                  className={styles['icon-cancel']}
                  type="button"
                  aria-label="Search"
                  onClick={() => {
                    setSearchQuery('');
                    setShowsFiltered(shows.regular);
                  }}
                >
                  <span className={classNames('visually-hidden')}>Очистить поиск</span>
                </button>
              ) : (
                <span className={styles['icon-search']} aria-label="Search" />
              )}
            </div>
          </header>
          <ul className={styles['content-list']}>
            {showsFiltered.map(show => (
              <li key={show.id}>
                <NavLink className={styles['content-item']} to={`/shows/${show.slug}`}>
                  <div className={styles.episodes}>
                    <strong>{show.episodes}</strong>
                    <p>episodes available</p>
                  </div>
                  <h3>{show.title}</h3>
                  <small>{show.description}</small>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ShowsPage;
