import HistoryApiService from './HistoryApiService';

export interface Image {
  id: string;
  image: string;
  title: string;
  description: string;
  copyright: string;
}

export const fetchImages = async (code: string): Promise<Image[]> =>
  new HistoryApiService().setEndpoint(`/galleries/${code}`).get();
