import React from 'react';
import { Moment } from 'moment';
import SwiperCore, { History, Navigation } from 'swiper';
// import classNames from 'classnames/bind';

import styles from './ScheduleList.module.scss';
import * as ScheduleApiService from '../../../services/api/ScheduleApiService';
import ScheduleListItem from '../ScheduleListItem';

interface Props {
  date: Moment;
}

interface State {
  schedule: ScheduleApiService.Programme[];
}

class ScheduleList extends React.Component<Props, State> {
  state: State = {
    schedule: []
  };

  async componentDidMount() {
    SwiperCore.use([Navigation, History]);
    await this.fetchTimetable();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { date } = this.props;
    if (prevProps.date.unix() !== date.unix()) {
      await this.fetchTimetable();
    }
  }

  async fetchTimetable() {
    const { date } = this.props;
    const schedule = await ScheduleApiService.fetchTimetable(
      date.format('DD'),
      date.format('MM'),
      date.format('YYYY')
    );
    this.setState({ schedule });
  }

  render() {
    const { schedule } = this.state;
    return (
      <div className={styles.container}>
        {schedule.map(programme => (
          <ScheduleListItem key={programme.id} programme={programme} />
        ))}
      </div>
    );
  }
}

export default ScheduleList;
