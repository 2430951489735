import React from 'react';
// import classNames from 'classnames';
import { RouteComponentProps } from 'react-router-dom';
import * as ShowsApiService from '../../services/api/ShowsApiService';
import styles from './ShowPage.module.scss';

interface MatchParams {
  slug: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const ShowPage = (props: Props) => {
  const {
    match: {
      params: { slug }
    }
  } = props;
  const [show, setShow] = React.useState<ShowsApiService.ShowFull | null>(null);

  React.useEffect(() => {
    const fetchShow = async () => {
      setShow(await ShowsApiService.fetchShow(slug));
    };
    fetchShow();
  }, [slug]);

  if (!show) {
    return null;
  }

  return (
    <section className={styles['shows-page']}>
      <div className={styles['hero-asset']}>
        <img src={show.detail_image} alt={show.title} />
      </div>
      <div className={styles['hero-meta-container']}>
        <h1 className={styles['hero-title']}>{show.title}</h1>
        <ul className={styles['series-info']}>
          {Number(show.seasons) > 0 &&
          <li>
            <a href="/">
              <span className="series-info-count">{show.seasons}</span>
              Сезонов
            </a>
          </li>
          }
          {Number(show.episodes) > 0 &&
          <li>
            <a href="/">
              <span className="series-info-count">{show.episodes}</span>
              Эпизодов
            </a>
          </li>
          }
        </ul>
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles['show-landing']} dangerouslySetInnerHTML={{ __html: show.text }}/>
    </section>
  );
};

export default ShowPage;
