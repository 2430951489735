import { ResponseData } from './ApiAbstract';

export class ApiError extends Error {
  readonly data?: ResponseData;

  readonly status?: number;

  constructor(status: number, data?: ResponseData) {
    const message = data ? data.message : 'Некорректный ответ сервера';
    super(message);
    this.data = data;
    this.status = status;
    return this;
  }
}
