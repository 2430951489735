import React from 'react';
import classNames from 'classnames';
import styles from './ScheduleListItem.module.scss';
import * as ScheduleApiService from '../../../services/api/ScheduleApiService';

interface Props {
  programme: ScheduleApiService.Programme;
  expanded?: boolean;
  onChange(e: State): void;
}

interface State {
  expanded: boolean;
}

export default class ScheduleListItem extends React.Component<Props, State> {
  static defaultProps = {
    expanded: false,
    onChange: () => {}
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: props.expanded || false
    };
  }

  handleChange = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  render() {
    const { programme } = this.props;
    const { expanded } = this.state;
    return (
      <div className={classNames(styles.container, expanded ? styles.expanded : '')}>
        <button className={styles.preview} type="button" onClick={this.handleChange}>
          <div key={programme.id} className={styles['preview-content']}>
            <div className={styles.time}>{programme.time}</div>
            <div className={styles['preview-content-details']}>
              <div className={styles['show-title']}>{programme.show_title}</div>
              <div className={styles['episode-title']}>{programme.episode_title}</div>
            </div>
          </div>
          <div className={styles.arrow} />
        </button>
        <div className={classNames(styles.content, { [styles.expanded]: expanded })}>
          <div className={classNames(styles['content-inner'])}>
            <div className={styles['show-number']}>
              S{programme.season_number}|E{programme.episode_number}
            </div>
            <div className={styles['show-rating']}>{programme.rating}</div>
            {programme.image && (
              <div>
                <img src={programme.image} alt="" />
              </div>
            )}
            <div className={styles.description}>{programme.description}</div>
          </div>
        </div>
      </div>
    );
  }
}
