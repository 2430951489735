import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../components/Header';
import Footer from '../components/Footer';
import styles from './CommonLayout.module.scss';

interface Props extends RouteComponentProps {
  Layout?: React.ReactElement;
  Page?: any;
  component: React.ReactElement;
  page: any;
}

const CommonLayout = ({ component: Layout, page: Page, match, ...rest }: Props) => {
  const [lockScroll, setLockScroll] = React.useState(false);
  return (
    <div className={classNames(styles.layout, { [styles.locked]: lockScroll })}>
      <Header
        isMain={match.url === '/'}
        onBurgerMenuShow={() => {
          setLockScroll(true);
        }}
        onBurgerMenuHide={() => {
          setLockScroll(false);
        }}
      />
      <div className={classNames(styles.content)}>
        <div className={classNames(styles['content-inner'])}>
          <div className={styles['content-page']}>
            <Page match={match} {...rest} />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
