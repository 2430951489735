import * as querystring from 'query-string';

import { ApiError } from './Errors';

declare global {
  interface Window {
    CORE_API_URL: string;
    SURVEY_API_URL: string;
    INGESTION_API_URL: string;
  }
}

type body = Record<string, string>;

export interface ResponseData {
  success: boolean;
  code: string;
  message?: string;
}

export default class ApiAbstract {
  protected baseUrl?: string;

  protected endpoint?: string;

  protected params: Record<string, string>;

  protected body: body;

  protected headers: Record<string, string>;

  protected abortController: AbortController;

  constructor() {
    this.params = {};
    this.body = {};
    this.headers = {};
    this.abortController = new AbortController();
    if (new.target === ApiAbstract) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }
  }

  setEndpoint(endpoint: string) {
    this.endpoint = endpoint;
    return this;
  }

  setParams(params: Record<string, string>) {
    this.params = { ...this.params, ...params };
    return this;
  }

  setBody(body: body) {
    this.body = body;
    return this;
  }

  async get() {
    return this.send({
      method: 'GET',
      headers: { ...this.getHeaders() }
    });
  }

  async post() {
    return this.sendRequestWithBody('POST');
  }

  async put() {
    return this.sendRequestWithBody('PUT');
  }

  async delete() {
    return this.sendRequestWithBody('DELETE');
  }

  abort() {
    this.abortController.abort();
    return this;
  }

  protected async sendRequestWithBody(method: string) {
    return this.send({
      method,
      headers: this.getHeaders(),
      body: querystring.stringify(this.body)
    });
  }

  public getUri() {
    if (this.baseUrl && this.endpoint) {
      return this.baseUrl + this.endpoint;
    }
    return undefined;
  }

  public getQuery() {
    if (this.params) {
      return Object.entries(this.params)
        .map(pair => pair.map(encodeURIComponent).join('='))
        .join('&');
    }
    return null;
  }

  protected getHeaders() {
    return this.headers;
  }

  protected setHeaders(headers: object) {
    this.headers = Object.assign({}, this.headers, headers);
    return this;
  }

  protected checkRequestData() {
    if (!this.baseUrl) {
      throw new Error('api is not set');
    }
    if (!this.endpoint) {
      throw new Error('endpoint is not set');
    }
  }

  protected async send(init: RequestInit) {
    this.checkRequestData();
    let uri = this.getUri();
    if (!uri) {
      throw new Error('No uri specified');
    }
    const query = this.getQuery();
    if (query) {
      uri += `?${query}`;
    }
    const response = await fetch(uri, { ...init, signal: this.abortController.signal });
    return this.handleResponseInternal(response);
  }

  protected async handleResponseInternal(response: Response) {
    const data = await response.json();
    switch (response.status) {
      case 200:
      case 201:
        // if it is errors free response - then just return the response json object
        return this.handleResponse(data);
      default:
        throw new ApiError(response.status, data);
    }
  }

  public handleResponse(data: any) {
    // to be overwritten
    return data;
  }
}
