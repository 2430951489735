import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/ru';

import './declarations.d';
import './index.scss';
import App from './App';

moment.locale('ru');

ReactDOM.render(<App />, document.getElementById('root'));
