import React from 'react';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import DayCalendar from '../Calendar';
import IconArrow from '../../Icons/IconArrow';
import styles from './PageTitle.module.scss';

interface Props {
  title: string;
  withCalendar?: boolean;
  calendarValue?: Moment;
  onPickDate?(date: Moment): void;
}

const PageTitle = ({
  title,
  withCalendar = false,
  calendarValue = moment(),
  onPickDate = () => {}
}: Props) => {
  const [currentDate, setCurrentDate] = React.useState(calendarValue);
  const [showCalendar, setShowCalendar] = React.useState(false);

  React.useEffect(() => {
    if (withCalendar) {
      setCurrentDate(calendarValue);
    }
  }, [withCalendar, calendarValue]);

  return (
    <div className={classNames(styles['page-title'], { [styles['history-title']]: withCalendar })}>
      <div className={styles.container}>
        <div className={styles.inner}>
          {/* eslint-disable-next-line react/no-danger */}
          <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
          {withCalendar && (
            <div className={styles.calendar}>
              <button
                type="button"
                className={classNames(styles['calendar-value'], { [styles['calendar-value-active']]: showCalendar })}
                onClick={() => setShowCalendar(true)}
              >
                {currentDate.format('DD MMMM')}
                <IconArrow />
              </button>
              {showCalendar && (
                <DayCalendar
                  value={calendarValue}
                  onSelect={date => {
                    setCurrentDate(date);
                    onPickDate(date);
                    setShowCalendar(false);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
