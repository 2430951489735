import HistoryApiService from './HistoryApiService';

export interface Provider {
  id: string;
  image: string;
  link: string;
}

export const fetchProviders = async (): Promise<Provider[]> =>
  new HistoryApiService().setEndpoint('/providers').get();
