import React from 'react';
import classNames from 'classnames';
import * as FeaturedApiService from '../../services/api/FeaturedApiService';
import CardFeatured from '../../components/CardFeatured';
import styles from './MainPage.module.scss';

interface Props {}

let sizeIndex = 0;
const getSize = (index: number) => {
  const sizes = [100, 60, 40];
  const size = sizes[sizeIndex];
  if (index === sizes.length - 1) {
    sizeIndex = 0;
  } else {
    sizeIndex += 1;
  }
  return size;
};

const MainPage = (props: Props) => {
  sizeIndex = 0;
  const [banners, setBanners] = React.useState<FeaturedApiService.Banner[]>([]);

  const mounted = React.useRef<boolean>();
  React.useEffect(() => {
    if (!mounted.current) {
      const onMount = async () => {
        setBanners(await FeaturedApiService.fetchFeatured());
      };
      onMount();
      mounted.current = true;
    }
  });

  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.featured)}>
        {banners.map((banner, index) => (
          <CardFeatured
            key={banner.id}
            title={banner.title}
            image={banner.image}
            label={banner.label}
            description={banner.description}
            link={banner.link}
            linkText={banner.link_text}
            width={getSize(index)}
            isMain={index === 0}
          />
        ))}
      </div>
    </div>
  );
};

export default MainPage;
