import React from 'react';

const IconTwitter = () => (
  <svg
    aria-hidden="false"
    role="img"
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6095 28.9907C15.8261 29.0649 18.0351 28.6938 20.1059 27.8994C22.1766 27.1049 24.0672 25.9032 25.6659 24.3653C27.2646 22.8274 28.5389 20.9845 29.4136 18.9455C30.2882 16.9064 30.7454 14.7127 30.7582 12.4939C31.774 11.2362 32.5283 9.78813 32.9767 8.23469C33.0102 8.11195 33.0075 7.98215 32.969 7.86092C32.9304 7.73968 32.8576 7.63219 32.7594 7.55137C32.6612 7.47054 32.5418 7.41985 32.4154 7.40539C32.2891 7.39092 32.1613 7.4133 32.0474 7.46983C31.5159 7.72577 30.9176 7.80843 30.3368 7.7062C29.7559 7.60396 29.2217 7.32196 28.8095 6.89994C28.2833 6.32342 27.6467 5.85844 26.9376 5.53254C26.2285 5.20665 25.4612 5.02648 24.6811 5.00271C23.9011 4.97893 23.1243 5.11204 22.3966 5.39414C21.669 5.67623 21.0053 6.10159 20.445 6.64499C19.6778 7.38834 19.1159 8.31761 18.8138 9.34249C18.5117 10.3674 18.4798 11.453 18.7212 12.4939C13.6995 12.7938 10.2518 10.4093 7.49358 7.1399C7.41072 7.04602 7.30233 6.97831 7.18164 6.94504C7.06096 6.91177 6.9332 6.91438 6.81397 6.95255C6.69474 6.99072 6.5892 7.0628 6.51024 7.15999C6.43128 7.25717 6.38231 7.37526 6.36932 7.49983C5.84388 10.4159 6.22292 13.423 7.45553 16.1173C8.68815 18.8116 10.7153 21.0639 13.2648 22.5719C11.5554 24.5333 9.1548 25.7583 6.5642 25.9913C6.42541 26.0143 6.29729 26.0802 6.19777 26.1797C6.09825 26.2792 6.03231 26.4073 6.00918 26.5461C5.98605 26.685 6.00689 26.8276 6.06878 26.954C6.13067 27.0804 6.23051 27.1842 6.35433 27.251C8.60813 28.3779 11.0901 28.973 13.6095 28.9907"
      stroke="white"
      strokeWidth="1.74922"
    />
  </svg>
);

export default IconTwitter;
