import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface Props extends RouteProps {
  component: React.ComponentType<any>;
  page: React.ComponentType<any>;
}

export default ({ component: Layout, page: Page, ...rest }: Props) => (
  <Route {...rest} render={props => <Layout page={Page} {...props} />} />
);
