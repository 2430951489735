import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Header.module.scss';
import BurgerButton from '../Atoms/BurgerButton';

interface Props {
  isMain: boolean;
  onBurgerMenuShow?: () => void;
  onBurgerMenuHide?: () => void;
}

const Header = ({ isMain, onBurgerMenuShow, onBurgerMenuHide }: Props) => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  const doShowBurgerMenu = () => {
    setShowBurgerMenu(true);
    if (onBurgerMenuShow) {
      onBurgerMenuShow();
    }
  };

  const doHideBurgerMenu = () => {
    setShowBurgerMenu(false);
    if (onBurgerMenuHide) {
      onBurgerMenuHide();
    }
  };

  return (
    <div className={classNames([styles.container, { [styles.main]: isMain }])}>
      <div className={classNames(styles.half)}>
        <NavLink to="/">
          <img src="/assets/i/logo.svg" alt="" className={classNames(styles.image)} />
        </NavLink>
        <div className={classNames(styles.menu)}>
          <NavLink to="/shows" className={classNames(styles.link)}>
            Программы
          </NavLink>
          <NavLink to="/schedule" className={classNames(styles.link)}>
            Расписание
          </NavLink>
          <NavLink to="/this-day-in-history" className={classNames(styles.link)}>
            Этот день в истории
          </NavLink>
          <NavLink to="/connect" className={classNames(styles.link)}>
            Как подключиться
          </NavLink>
        </div>
      </div>
      <div className={classNames(styles.half)}>
        <BurgerButton onClick={doShowBurgerMenu} />
        {showBurgerMenu ? (
          <div className={classNames(styles['burger-menu'])}>
            <button
              className={classNames(styles['burger-menu-close'])}
              type="button"
              onClick={doHideBurgerMenu}
            >
              <span className={styles.line} />
              <span className={styles.line} />
              <span className={styles.line} />
            </button>

            <div className={classNames(styles['burger-menu-inner'])}>
              <div className={classNames(styles['mobile-logo'])}>
                <NavLink to="/" onClick={doHideBurgerMenu}>
                  <strong className="brand">HISTORY</strong>
                </NavLink>
              </div>
              <div className={classNames(styles['burger-menu-left'])}>
                <NavLink to="/" onClick={doHideBurgerMenu}>
                  <img
                    src="/assets/i/logo.svg"
                    alt=""
                    className={classNames(styles['burger-menu-logo'])}
                  />
                </NavLink>
              </div>
              <div className={classNames(styles['burger-menu-right'])}>
                <div className={classNames(styles['burger-menu-list'])}>
                  <NavLink
                    to="/shows"
                    className={classNames(styles['burger-menu-link'])}
                    onClick={doHideBurgerMenu}
                  >
                    Программы
                  </NavLink>
                  <NavLink
                    to="/schedule"
                    className={classNames(styles['burger-menu-link'])}
                    onClick={doHideBurgerMenu}
                  >
                    Расписание
                  </NavLink>
                  <NavLink
                    to="/this-day-in-history"
                    className={classNames(styles['burger-menu-link'])}
                    onClick={doHideBurgerMenu}
                  >
                    Этот день в истории
                  </NavLink>
                  <NavLink
                    to="/connect"
                    className={classNames(styles['burger-menu-link'])}
                    onClick={doHideBurgerMenu}
                  >
                    Как подключиться
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
