import HistoryApiService from './HistoryApiService';

export interface Banner {
  id: string;
  title: string;
  description: string;
  image: string;
  link: string;
  link_text: string; // eslint-disable-line camelcase
  label: string;
}

export const fetchFeatured = async (): Promise<Banner[]> =>
  new HistoryApiService().setEndpoint('/featured').get();
