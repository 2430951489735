import React from 'react';
import classNames from 'classnames';
import styles from './CardFeatured.module.scss';

interface Props {
  title: string;
  label: string;
  image: string;
  description: string;
  width: number;
  isMain: boolean;
  link: string;
  linkText: string;
}

const CardFeatured = ({
  width,
  title,
  label,
  image,
  description,
  isMain,
  link,
  linkText
}: Props) => (
  <div
    className={classNames([styles.container, { [styles.main]: isMain }])}
    style={{ width: `${width}%` }}
  >
    <div className={styles.image}>
      <img src={image} alt="" aria-hidden />
    </div>
    <div className={styles.detail}>
      <span className={styles.label}>{label}</span>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.description}>{description}</div>
      <a href={link} className={styles.link}>
        {linkText}
      </a>
    </div>
  </div>
);

export default CardFeatured;
