import React from 'react';

const IconFillPinterest = () => (
  <svg
    aria-hidden="false"
    role="img"
    viewBox="0 0 20 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M0 9.33C0 3.56 5.28 0 10.63 0 15.53 0 20 3.38 20 8.55c0 4.86-2.48 10.25-8 10.25a4.22 4.22 0 01-3.61-1.87c-1.19 4.7-1.09 5.41-3.72 9l-.23.07-.14-.16c-.09-1-.23-2-.23-2.94 0-3.19 1.47-7.8 2.19-10.89a6.12 6.12 0 01-.5-2.64C5.75 7.8 6.85 5.8 8.63 5.8a2 2 0 012 2.23c0 2-1.38 3.94-1.38 5.91a2.31 2.31 0 002.41 2.28c3.59 0 4.7-5.19 4.7-8 0-3.7-2.62-5.72-6.17-5.72a7 7 0 00-7.3 7.2c0 2 1.23 3 1.23 3.53s-.3 1.84-.81 1.84A1.56 1.56 0 013 15c-2.23-.67-3-3.66-3-5.7z"
    />
  </svg>
);

export default IconFillPinterest;
