import React from 'react';
import classNames from 'classnames';
import * as ProvidersApiService from '../../services/api/ProvidersApiService';
import styles from './ConnectPage.module.scss';

interface Props {}

const ConnectPage = (props: Props) => {
  const [providers, setProviders] = React.useState<ProvidersApiService.Provider[]>([]);

  const mounted = React.useRef<boolean>();
  React.useEffect(() => {
    if (!mounted.current) {
      const onMount = async () => {
        setProviders(await ProvidersApiService.fetchProviders());
      };
      onMount();
      mounted.current = true;
    }
  });

  return (
    <section className={classNames(styles['connect-page'])}>
      <h1 className={styles['page-title']}>Как подключиться</h1>
      <p className={styles['lead-text']}>
        Канал History доступен в&nbsp;пакетах следующих операторов:
      </p>
      <h2 className={classNames('visually-hidden')}>Операторы</h2>
      <ul className={styles['providers-list']}>
        {providers.map(provider => (
          <li key={provider.id} className={styles.provider}>
            <a href={provider.link}>
              <div className={styles['provider-logo']}>
                <img src={provider.image} alt="" width={270} />
              </div>
              <div className={classNames(styles['svg-wrapper'])}>
                <svg height="60" width="320" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect className={styles.shape} height="60" width="320" />
                </svg>
                <span className={classNames(styles['link-text'])}>ПЕРЕЙТИ НА САЙТ</span>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ConnectPage;
