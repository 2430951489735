import React from 'react';
import classNames from 'classnames/bind';
import * as GalleryApiService from '../../services/api/GalleryApiService';
import styles from './GalleryModal.module.scss';

interface Props {
  images: GalleryApiService.Image[];
  onClose(): void;
}

const GalleryModal = ({ images, onClose }: Props) => {
  const [imageIndex, setImageIndex] = React.useState(0);
  const image = images[imageIndex];

  const updateImageIndex = (newImageIndex: number) => {
    if (newImageIndex < 0) {
      setImageIndex(images.length - 1);
    } else if (newImageIndex === images.length) {
      setImageIndex(0);
    } else {
      setImageIndex(newImageIndex);
    }
  };

  return (
    <div className={classNames([styles.modal])}>
      <button type="button" onClick={onClose}>
        Close
      </button>
      <div>
        <div className={styles.image} style={{ backgroundImage: `url(${image.image})` }} />
        <div>{image.title}</div>
        <div dangerouslySetInnerHTML={{ __html: image.description }} />
      </div>
      <button type="button" onClick={() => updateImageIndex(imageIndex - 1)}>
        Prev
      </button>
      <button type="button" onClick={() => updateImageIndex(imageIndex + 1)}>
        Next
      </button>
      <div>
        {imageIndex + 1} / {images.length}
      </div>
    </div>
  );
};

export default GalleryModal;
